//定义路由
export default [
    {
        path: '/',
        name: 'index',
        component: resolve => require.ensure([], () => resolve(require('@/views/index/mainPage.vue')), 'index'),
        redirect: "/index",
        children: [
            {
                path: '/index',
                meta: {title: 'index'},
                component: resolve => require.ensure([], () => resolve(require('@/views/index/index.vue')), 'index'),
            },
            /*{
                path: '/crowdfunding',
                meta: {title: 'crowdfunding'},
                component: resolve => require.ensure([], () => resolve(require('@/views/index/crowdfunding/aboutCrowdfunding.vue')), 'index'),
            },
            {
                path: '/solarProjects',
                meta: {title: 'solarProjects'},
                component: resolve => require.ensure([], () => resolve(require('@/views/index/crowdfunding/solarProjects.vue')), 'index'),
            },
            {
                path: '/solarProjectDetail',
                meta: {title: 'solarProjectDetail'},
                component: resolve => require.ensure([], () => resolve(require('@/views/index/crowdfunding/solarProjectDetail.vue')), 'index'),
                props: (route) => ({serial: route.query.serial}),
            },
            {
                path: '/systemInstallation',
                meta: {title: 'systemInstallation'},
                component: resolve => require.ensure([], () => resolve(require('@/views/index/systemInstallation/systemInstallation.vue')), 'index'),
            },*/
            {
                path: '/aboutUs',
                meta: {title: 'aboutUs'},
                component: resolve => require.ensure([], () => resolve(require('@/views/index/aboutUs.vue')), 'index'),
            },
            {
                path: '/series/smart',
                meta: {title: 'smart'},
                component: resolve => require.ensure([], () => resolve(require('@/views/index/product/series.vue')), 'index'),
                props: (route) => ({series: 'smart'}),
            },
            {
                path: '/series/diode',
                meta: {title: 'diode'},
                component: resolve => require.ensure([], () => resolve(require('@/views/index/product/series.vue')), 'index'),
                props: (route) => ({series: 'diode'}),
            },
            {
                path: '/series/junctionBox',
                meta: {title: 'box'},
                component: resolve => require.ensure([], () => resolve(require('@/views/index/product/series.vue')), 'index'),
                props: (route) => ({series: 'box'}),
            },
            {
                path: '/product',
                component: resolve => require.ensure([], () => resolve(require('@/views/index/product/product.vue')), 'index'),
                redirect: "/product/monitor",
                children: [
                    {
                        path: '/product/detail',
                        meta: {title: 'productDetail'},
                        component: resolve => require.ensure([], () => resolve(require('@/views/index/product/productDetail.vue')), 'index'),
                        props: (route) => ({series: route.query.series, product: route.query.product}),
                    },
                ]
            },
            {
                path: '/news',
                component: resolve => require.ensure([], () => resolve(require('@/views/index/news/news.vue')), 'index'),
                redirect: "/news/companyNews",
                children: [
                    {
                        path: '/news/companyNews',
                        component: resolve => require.ensure([], () => resolve(require('@/views/index/news/newsAtAll.vue')), 'index'),
                        props: (route) => ({types: 'companyNews'}),
                        redirect: "/news/companyNews/list",
                        children: [
                            {
                                path: '/news/companyNews/list',
                                meta: {title: 'companyNews'},
                                component: resolve => require.ensure([], () => resolve(require('@/views/index/news/newsList.vue')), 'index'),
                                props: (route) => ({types: 'companyNews'}),
                            },
                            {
                                path: '/news/companyNews/detail',
                                meta: {title: 'companyNewsDetail'},
                                component: resolve => require.ensure([], () => resolve(require('@/views/index/news/newsDetail.vue')), 'index'),
                                props: (route) => ({currentPaper: route.query.goalPaper, types: 'companyNews'}),
                            },
                        ]
                    },
                    {
                        path: '/news/projectSharing',
                        component: resolve => require.ensure([], () => resolve(require('@/views/index/news/newsAtAll.vue')), 'index'),
                        props: (route) => ({types: 'projectSharing'}),
                        redirect: "/news/projectSharing/list",
                        children: [
                            {
                                path: '/news/projectSharing/list',
                                meta: {title: 'projectSharing'},
                                component: resolve => require.ensure([], () => resolve(require('@/views/index/news/newsList.vue')), 'index'),
                                props: (route) => ({types: 'projectSharing'}),
                            },
                            {
                                path: '/news/projectSharing/detail',
                                meta: {title: 'projectSharingDetail'},
                                component: resolve => require.ensure([], () => resolve(require('@/views/index/news/newsDetail.vue')), 'index'),
                                props: (route) => ({currentPaper: route.query.goalPaper, types: 'projectSharing'}),
                            },
                        ]
                    },
                    {
                        path: '/news/searchingResult',
                        component: resolve => require.ensure([], () => resolve(require('@/views/index/news/newsAtAll.vue')), 'index'),
                        props: (route) => ({types: 'searchingResult'}),
                        redirect: "/news/searchingResult/list",
                        children: [
                            {
                                path: '/news/searchingResult/list',
                                meta: {title: 'searchingResult'},
                                component: resolve => require.ensure([], () => resolve(require('@/views/index/news/newsList.vue')), 'index'),
                                props: (route) => ({types: 'searchingResult'}),
                            },
                            {
                                path: '/news/searchingResult/detail',
                                meta: {title: 'searchingResultDetail'},
                                component: resolve => require.ensure([], () => resolve(require('@/views/index/news/newsDetail.vue')), 'index'),
                                props: (route) => ({currentPaper: route.query.goalPaper, types: 'searchingResult'}),
                            },
                        ]
                    },
                ]
            },
            {
                path: '/support',
                component: resolve => require.ensure([], () => resolve(require('@/views/index/support/support.vue')), 'index'),
                redirect: "/support/normalQuestion",
                children: [
                    {
                        path: '/support/normalQuestion',
                        component: resolve => require.ensure([], () => resolve(require('@/views/index/support/normalQuestion.vue')), 'index'),
                    },
                    {
                        path: '/support/contact',
                        component: resolve => require.ensure([], () => resolve(require('@/views/index/support/contactUs.vue')), 'index'),
                        props: (route) => ({public: true}),
                    },
                    {
                        path: '/support/job',
                        component: resolve => require.ensure([], () => resolve(require('@/views/index/support/job.vue')), 'index'),
                        props: (route) => ({public: true}),
                    },
                    {
                        path: '/support/download',
                        component: resolve => require.ensure([], () => resolve(require('@/views/index/support/download.vue')), 'index'),
                    },
                    {
                        path: '/support/traceability',
                        component: resolve => require.ensure([], () => resolve(require('@/views/index/support/traceability.vue')), 'index'),
                    },
                ]
            },
        ]
    }
]




