import Vue from 'vue';
import App from './App.vue';
import router from '@/router/mainRouter';
import store from './store';
import ElementUI, {MessageBox} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import Axios from 'axios';
import jwtDecode from 'jwt-decode';
import Dayjs from 'dayjs';
import VueSkycons from 'vue-skycons';
import NodeRSA from 'node-rsa';
import CryptoJS from '../public/js/utils.js';
import JSEncrypt from 'jsencrypt';
import VueI18n from './assets/languages/index';
import i18n from './assets/languages/index';
import hzqingVueTimeline from 'hzqing-vue-timeline';
import VueClipboard from 'vue-clipboard2';
import progressive from 'progressive-image/dist/vue';
import VueCropper from 'vue-cropper';


Vue.use(VueCropper);
Vue.use(progressive, {removePreview: true, scale: true});
Vue.use(VueClipboard);
Vue.use(hzqingVueTimeline);

var utc = require('dayjs/plugin/utc');
Dayjs.extend(utc);

Vue.use(VueI18n);
Vue.use(ElementUI, {i18n: (key, value) => i18n.t(key, value)});
Vue.use(VueSkycons, {color: '#909399'});
Vue.config.productionTip = false;

let DO_MAIN = window.location.host;
let API = `https://${DO_MAIN}/api`;

// let DATA_PATH = 'https://img.spo.cn/';
let DATA_PATH = 'https://data.spo.cn/data/';

Vue.prototype.DATA_PATH = DATA_PATH;
Vue.prototype.baseApi = API;
Vue.prototype.doMain = DO_MAIN;
Vue.prototype.axios = Axios;
Vue.prototype.$dayjs = Dayjs;
Vue.prototype.$jwtDecode = jwtDecode;
Vue.prototype.$nodeRSA = NodeRSA;
Vue.prototype.$cryptoJS = CryptoJS;

Axios.defaults.baseURL = API;
Axios.defaults.withCredentials = true;
var $http = Axios.create({baseURL: API, withCredentials: true});
Vue.prototype.$http = $http;

// 创建Axios的新实例-->加密
var $axios = Axios.create({
    baseURL: API,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json;charset=UTF-8"
    },
    transformRequest: [function (data) {// 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
        typeof (data) == 'string' ? null : data = JSON.stringify(data);
        let aseKey = CryptoJS.randomPwd(16);
        let cipherText = CryptoJS.encrypt(data, aseKey);
        let encryptStr = new JSEncrypt();

        let postData = {};
        if (store.state.keys.serverKey) {
            encryptStr.setPublicKey(store.state.keys.serverKey, 'utf8');
        } else {
            store.dispatch('getServerKey').then(key => {
                if (key) {
                    encryptStr.setPublicKey(key, 'utf8');
                }
            })
        }
        //使用服务器公钥 加密ase密码
        postData = Object.assign({cipherAes: encryptStr.encrypt(aseKey, 'utf8'),}, {cipherText});
        return JSON.stringify(postData);
    }]
});
Vue.prototype.$axios = $axios;

//添加请求拦截器
Axios.interceptors.request.use((config) => {
        config.headers['lang'] = i18n.locale;
        config.cancelToken = new Axios.CancelToken(cancel => {
            store.commit('PUSH_CANCEL', {cancelToken: cancel});
        });
        return config;
    }, (error) => {
        return Promise.reject(error);
    }
)
//添加请求拦截器
$axios.interceptors.request.use((config) => {
        //判断是否存在token，如果存在将每个页面header都添加token
        store.state.keys.publicKey ? config.headers['Checksign'] = store.state.keys.publicKey : null;
        config.headers['lang'] = i18n.locale;
        config.cancelToken = new Axios.CancelToken(cancel => {
            store.commit('PUSH_CANCEL', {cancelToken: cancel});
        });
        return config;
    }, (error) => {
        return Promise.reject(error);
    }
)
//添加响应拦截器
Axios.interceptors.response.use((response) => {
        if (response.data.code == 10001 || response.data.code == 10002) {
            if (store.state.token.status) {
                store.commit('set_token', {token: sessionStorage.getItem('token'), status: false});
                MessageBox.confirm(response.data.message, VueI18n.t('message.message'), {
                    type: 'warning',
                    confirmButtonText: VueI18n.t('common.confirmButtonText'),
                    showClose: false,
                    showCancelButton: false,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    closeOnHashChange: false
                }).then(() => {
                    router.replace('/login')
                });
            }
            return response;
        } else if (response.data.code == 10006) {
            return response;
        } else {
            return response;
        }
    },
    (error) => {
        return Promise.reject(error);
    }
)
//添加响应拦截器
$axios.interceptors.response.use((response) => {
        if (response.data.code == 10005) {
            store.dispatch('getServerKey').then(key => {});
        } else {
            if (response.data.cipherAes) {
                let encryptStr = new JSEncrypt();
                encryptStr.setPrivateKey(store.state.keys.privateKey, 'utf8');
                let aseKey = encryptStr.decrypt(response.data.cipherAes, 'utf8');
                let newResponse = {};
                newResponse.data = JSON.parse(CryptoJS.decrypt(response.data.cipherText, aseKey));
                if (newResponse.data.code == 10001 || newResponse.data.code == 10002) {
                    if (store.state.token.status) {
                        store.commit('set_token', {token: sessionStorage.getItem('token'), status: false});
                        MessageBox.confirm(response.data.message, VueI18n.t('message.message'), {
                            type: 'warning',
                            confirmButtonText: VueI18n.t('common.confirmButtonText'),
                            showClose: false,
                            showCancelButton: false,
                            closeOnClickModal: false,
                            closeOnPressEscape: false,
                            closeOnHashChange: false
                        }).then(() => {
                            router.replace('/login')
                        });
                    }
                    response.data.message = '';
                    return response;
                } else if (response.data.code == 10006) {
                    return response;
                } else {
                    return newResponse;
                }
                // return newResponse
            } else {
                if (response.data.code == 10001 || response.data.code == 10002) {
                    if (store.state.token.status) {
                        store.commit('set_token', {token: sessionStorage.getItem('token'), status: false});
                        MessageBox.confirm(response.data.message, VueI18n.t('message.message'), {
                            type: 'warning',
                            confirmButtonText: VueI18n.t('common.confirmButtonText'),
                            showClose: false,
                            showCancelButton: false,
                            closeOnClickModal: false,
                            closeOnPressEscape: false,
                            closeOnHashChange: false
                        }).then(() => {
                            router.replace('/login')
                        });
                    }
                    response.data.message = '';
                    return response;
                } else if (response.data.code == 10006) {
                    return response;
                } else {
                    return response;
                }
            }
        }
    }, (error) => {
        return Promise.reject(error);
    }
)
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0)
})
//创建和挂载根实例
new Vue({router, store, i18n, render: h => h(App)}).$mount('#app');
