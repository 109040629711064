
//定义路由
export default  [
  {
  path:'/visitor/info',
  meta: { requireAuth: true,roles:['V'] },
  component:()=>import(/* webpackChunkName: "visitor" */ '@/views/powerInfo/Info.vue'),
  redirect:'/visitor/info/powerInfo',
  children:[
    {
      path:'/visitor/info/powerInfo',
      meta: { requireAuth: true,roles:['V'] },
      component:()=>import(/* webpackChunkName: "visitor" */ '@/views/powerInfo/powerInfoMain.vue'),
      redirect:'/visitor/info/powerInfo/powerData',
      children:[
          {
            path:"/visitor/info/powerInfo/powerData",
            meta: { requireAuth: true,roles:['V'] },
            component:()=>import(/* webpackChunkName: "visitor" */ '@/views/powerInfo/powerData.vue'),
          },
          {
            path:"/visitor/info/powerInfo/details",
            meta: { requireAuth: true,roles:['V'] },
            component:()=>import(/* webpackChunkName: "visitor" */ '@/views/powerInfo/details.vue'),
          },
          {
            path:"/visitor/info/powerInfo/layoutStatic",
            meta: { requireAuth: true,roles:['V'] },
            component:()=>import(/* webpackChunkName: "visitor" */ '@/views/powerInfo/layoutStatic.vue'),
          }
        ]
    },
    {
      path:'/visitor/info/modulesOnSale',
      meta: { requireAuth: true,roles:['V'] },
      component:()=>import(/* webpackChunkName: "visitor" */ '@/views/powerInfo/powerInfoMain.vue'),
      props: (route) => ({isSale: true}) ,
      redirect:'/visitor/info/modulesOnSale/powerData',
      children:[
          {
            path:"/visitor/info/modulesOnSale/powerData",
            meta: { requireAuth: true,roles:['V'] },
            component:()=>import(/* webpackChunkName: "visitor" */ '@/views/powerInfo/powerData.vue'),
          },
          {
            path:"/visitor/info/modulesOnSale/details",
            meta: { requireAuth: true,roles:['V'] },
            component:()=>import(/* webpackChunkName: "visitor" */ '@/views/powerInfo/details.vue'),
          },
          {
            path:"/visitor/info/modulesOnSale/layoutStatic",
            meta: { requireAuth: true,roles:['V'] },
            component:()=>import(/* webpackChunkName: "visitor" */ '@/views/powerInfo/layoutStatic.vue'),
          }
        ]
    },
    {
      path:'/visitor/info/plantLocation',
      meta: { requireAuth: true,roles:['V'] },
      component:()=>import(/* webpackChunkName: "visitor" */ '@/views/plantLocation/plantLocation.vue')
    },
    {
      path:'/visitor/info/plantNewLocation',
      meta: { requireAuth: true,roles:['V'] },
      component:()=>import(/* webpackChunkName: "visitor" */ '@/views/plantLocation/plantNewLocation.vue')
    },
  ]

}

]




