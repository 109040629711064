import Vue from 'vue'
import VueI18n from 'vue-i18n'
import LangStorage from './lang'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import hkLocale from 'element-ui/lib/locale/lang/zh-TW'
import jaLocale from 'element-ui/lib/locale/lang/ja'
import thLocale from 'element-ui/lib/locale/lang/th'
import itLocale from 'element-ui/lib/locale/lang/it'

Vue.use(VueI18n)

const DEFAULT_LANG = 'zh_cn'
const locales = {
    zh_cn: Object.assign(require('./zh.json'), zhLocale),
    hk: Object.assign(require('./hk.json'), hkLocale),
    en: Object.assign(require('./en.json'), enLocale),
    ja: Object.assign(require('./ja.json'), jaLocale),
    th: Object.assign(require('./th.json'), thLocale),
    it: Object.assign(require('./it.json'), itLocale)
}
const i18n = new VueI18n({
    locale: LangStorage.getLang(DEFAULT_LANG),
    messages: locales
})

export default i18n
